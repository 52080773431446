import React, { useCallback, useEffect, useRef, useState } from "react";
import { copyTextToSystemClipboard } from "../../clipboard";
import { Dialog } from "../../components/Dialog";
import {
  clipboard,
  start,
  stop,
  share,
  shareIOS,
  shareWindows,
  IconEnvelope,
  IconGoogle,
  IconOffice365,
  IconCopy,
  GoogleMeetIcon,
} from "../../components/icons";
import { ToolButton } from "../../components/ToolButton";
import { t } from "../../i18n";
import "./RoomDialog.scss";
import Stack from "../../components/Stack";
import { AppState, UserInfo } from "../../types";
import { trackEvent } from "../../analytics";
import { getFrame, googleSignRedirect } from "../../utils";
import DialogActionButton from "../../components/DialogActionButton";
import QRCode from "qrcode.react";
import { Toast } from "../../components/Toast";
import { getCurrBoardMode, getItemWithExpiration, isCollabtionHost } from "../data/localStorage";
import { isGoogleMeetingStartedAtom, googleMeetingCodeAtom } from "./Collab";
import { useAtom } from "jotai";
import { isGoogleMeetAddonAtom, useDevice } from "../../components/App";
import { Button, Flex, Input, Radio } from "antd";
import clsx from "clsx";
import { BOARD_MODE, COOKIES, FLASH_COLLAB } from "../../constants";
import { stringToBase64 } from "../../data/encode";

const getShareIcon = () => {
  const navigator = window.navigator as any;
  const isAppleBrowser = /Apple/.test(navigator.vendor);
  const isWindowsBrowser = navigator.appVersion.indexOf("Win") !== -1;

  if (isAppleBrowser) {
    return shareIOS;
  } else if (isWindowsBrowser) {
    return shareWindows;
  }

  return share;
};

export const requestToken4flash = async (userInfo: UserInfo) => {
  if (FLASH_COLLAB.ENABLE_AUTH) {
    await fetch(FLASH_COLLAB.REQUEST_FLASH_AUTH_TOKEN, {
      method: "POST",
      body: JSON.stringify({ id: userInfo?.id, username: userInfo?.username, email: userInfo?.email, avatar: "https:/gravatar.com/avatar/abc123" }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

      })
      .then(async (data: any) => {
        if (data && data.code == 200) {
          const now = new Date().getTime();
          document.cookie = `${COOKIES.FLASH4AUTH}=${await stringToBase64(
            JSON.stringify(data.data.token4flash),
          )};Expires=${new Date(
            now + 60 * 60 * 24 * 1000,
          ).toUTCString()}; path=/; SameSite=None; Secure`;

        }
      })
      .catch((err) => {

      });
  }
}

const RoomDialog = ({
  handleClose,
  activeRoomLink,
  username,
  roomId,
  onUsernameChange,
  onRoomCreate,
  onRoomDestroy,
  setErrorMessage,
  theme,
  appState,
  setAppState,
  onEscalate,
  isMobile
}: {
  handleClose: () => void;
  activeRoomLink: string;
  username: string;
  roomId?: string;
  onUsernameChange: (username: string) => void;
  onRoomCreate: () => void;
  onRoomDestroy: () => void;
  setErrorMessage: (message: string) => void;
  theme: AppState["theme"];
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  onEscalate: (code: string) => void;
  isMobile: boolean;
}) => {
  const roomLinkInput = useRef<HTMLInputElement>(null);
  const [joinAddress, setJoinAddress] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [toast, setToast] = useState(false);
  const [isGoogleLogined, setIsGoogleLogined] = useState(false);
  const [isGoogleMeetingStarted, setIsGoogleMeetingStarted] = useAtom(
    isGoogleMeetingStartedAtom,
  );
  const [googleMeetingCode, setMeetingCode] = useAtom(googleMeetingCodeAtom);
  const googleMeetShareTextRef = React.useRef<HTMLParagraphElement>(null);
  const { userInfo } = appState;
  const timerRef = useRef<number>(0);
  const [isGoogleMeetAddon, _] = useAtom(isGoogleMeetAddonAtom);
  const [switchAction, setSwitchAction] = useState<"new" | "join">("new");
  const [isCanNew, setIsCanNew] = useState<boolean>(true);

  // const setIsGoogleMeetingStarted = (isStarted:boolean)=>{
  //   setAppState({isGoogleMeetingStarted:isStarted});
  // }
  // const setMeetingCode = (code:string)=>{
  //   setAppState({googleMeetingCode:code});
  // }
  const scheduleTimeout = useCallback(() => {
    timerRef.current = window.setTimeout(() => setToast(false), 2000);
  }, [toast]);



  useEffect(() => {
    const token = getItemWithExpiration("google_access_token");
    if (token) {
      fetch(`https://www.googleapis.com/oauth2/v3/userinfo`, {
        method: "GET",
        headers: new Headers({ Authorization: "Bearer " + token }),
      }).then(async (res) => {
        let val = await res.json();
        setAppState({ googleEmail: val.email, googleName: val.name });
      });
      setIsGoogleLogined(true);
    } else {
      setIsGoogleLogined(false);
    }
  }, []);

  useEffect(() => {
    scheduleTimeout();

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [scheduleTimeout]);

  const copyRoomLink = async () => {
    try {
      await copyTextToSystemClipboard(activeRoomLink);
      setToast(true);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    if (roomLinkInput.current) {
      roomLinkInput.current.select();
    }
  };

  const shareRoomLink = async () => {
    try {
      await navigator.share({
        title: t("roomDialog.shareTitle"),
        text: t("roomDialog.shareTitle"),
        url: activeRoomLink,
      });
    } catch (error: any) {
      // Just ignore.
    }
  };

  const selectInput = (event: React.MouseEvent<HTMLInputElement>) => {
    if (event.target !== document.activeElement) {
      event.preventDefault();
      (event.target as HTMLInputElement).select();
      copyRoomLink();
    }
  };

  const clickInput = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    if (event.target !== document.activeElement) {
      event.preventDefault();
      (event.target as HTMLInputElement).select();
    }
    copyRoomLink();
  };

  const handleQRCodeLoad = () => {
    setIsLoading(false);
  };

  const handleJoin = async () => {
    if (joinAddress != "") {
      if (joinAddress.startsWith("https") || joinAddress.startsWith("http")) {
        window.location.href = joinAddress;
      } else {
        await fetch(`/api/v1/getRoom/${joinAddress}`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + userInfo?.authorization,
          },
        })
          .then(async (response) => {
            if (response.ok) {
              const result = await response.json();
              if (result.data.roomId) {
                window.location.href = "/board/" + joinAddress;
              } else {
                throw new Error("error");
              }
            } else {
              throw new Error("error");
            }
          })
          .catch((err) => {
            alert(
              "Do not find this session, please start a new collaboration.",
            );
          });
      }
    }
  };

  const handleEscalated = async () => {
    if (isGoogleLogined) {
      if (
        !appState.userLicence?.quicklyForwardToGoogleMeet
      ) {
        alert(t("alerts.shouldGrade"));
        return;
      }

      let formData: FormData = new FormData();

      formData.append("roomId", roomId!);
      formData.append(
        "token",
        getItemWithExpiration("google_access_token"),
      );
      const response = await fetch("/api/v1/meet", {
        method: "POST",
        body: formData,
        headers: {
          Authorization:
            "Bearer " + userInfo?.authorization,
        },
      });
      const result = await response.json();
      setIsGoogleMeetingStarted(true);
      setMeetingCode(result.data.meetingCode);
      onEscalate(result.data.meetingCode);
    }
  }
  const renderRoomDialog = () => {
    const encodedInviteSubject = encodeURIComponent(
      t("roomDialog.share_invite_mail_title"),
    );
    const encodedInviteText = `${encodeURIComponent(activeRoomLink)}`;
    const encodedDefaultEmailText = `<a href="${encodeURIComponent(
      activeRoomLink,
    )}" target='_blank'>${encodeURIComponent(activeRoomLink)}</a>`;

    const PROVIDER_MAPPING = [
      {
        icon: IconEnvelope,
        tooltipKey: "roomDialog.share_default_email",
        url: `mailto:?subject=${encodedInviteSubject}&body=${encodedDefaultEmailText}`,
      },
      {
        icon: IconGoogle,
        tooltipKey: "roomDialog.share_google_email",
        url: `https://mail.google.com/mail/?view=cm&fs=1&su=${encodedInviteSubject}&body=${encodedInviteText}`,
      },
      {
        icon: IconOffice365,
        tooltipKey: "roomDialog.share_outlook_email",
        url: `https://outlook.office.com/mail/deeplink/compose?subject=${encodedInviteSubject}&body=${encodedInviteText}`,
      },
    ];




    return (
      <>
        <div className="action-switch">
          <Radio.Group
            value={switchAction}
            buttonStyle="outline"
            onChange={(e) => {
              setSwitchAction(e.target.value);
            }}
          >
            {isCanNew ? (<Radio.Button value="new">{t("labels.btn_new_collab")}</Radio.Button>) : (<Radio.Button disabled value="new">{t("labels.btn_new_collab")}</Radio.Button>)}
            <Radio.Button value="join">{t("labels.btn_join_collab")}</Radio.Button>
          </Radio.Group>
        </div>
        {switchAction == "new" ? (
          !activeRoomLink ? (
            <div className="action-content">
              <div className="side-content-body">
                {t("roomDialog.desc_intro")}
              </div>

              <div className="side-action">
                <a
                  className="action-button"
                  onClick={async () => {
                    trackEvent("share", "room creation", `ui (${getFrame()})`);
                    userInfo && await requestToken4flash(userInfo);
                    onRoomCreate();
                  }}
                >
                  {t("roomDialog.button_startSession")}
                </a>
              </div>
            </div>
          ) : (
            <div className="action-content">
              <div style={{ textAlign: "center" }}>
                {t("labels.room_collaborate_desc")}
              </div>
              <div className={clsx("room-info", { "room-info-mobile": isMobile })}>
                <div className="info-left">
                  <div className="">{t("roomDialog.share_number_title")}</div>
                  <div className="qrCode_container">
                    <QRCode
                      value={`${activeRoomLink}`}
                      onLoad={handleQRCodeLoad}
                    />
                  </div>
                  <div className="room-id">
                    {roomId}
                    <a
                      onClick={copyRoomLink}
                      title={t("roomDialog.share_copy")}
                      href={undefined}
                    >
                      {IconCopy}
                    </a>
                  </div>
                  <div className="share-content-title">
                    {t("roomDialog.share_invite_link")}
                  </div>
                  <div className="share-buttons">
                    {PROVIDER_MAPPING.map(({ icon, tooltipKey, url }, idx) => (
                      <a
                        aria-label={t(tooltipKey)}
                        href={url}
                        rel="noopener noreferrer"
                        target="_blank"
                        title={t(tooltipKey)}
                      >
                        {icon}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="info-right">
                  <div style={{ display: "flex", flexDirection: "column", marginLeft: isMobile ? 0 : "40px" }}>
                    <h2 className="google-meet-title">
                      {t("labels.googleMeet")}
                      {isGoogleLogined ? (
                        <p>
                          <span>
                            {appState.googleName}({appState.googleEmail})
                          </span>
                        </p>
                      ) : (
                        <span>
                          (
                          <a
                            href="javascript:;"
                            onClick={() => {
                              googleSignRedirect(false, isGoogleMeetAddon);
                            }}
                          >
                            {t("buttons.signIn")}
                          </a>
                          )
                        </span>
                      )}
                    </h2>
                    {isGoogleMeetingStarted ? (
                      <p ref={googleMeetShareTextRef}>
                        {t("labels.room_google_meet_desc1")}{" "}
                        <a
                          id="gotogooglemeet"
                          href={`https://meet.google.com/${googleMeetingCode}`}
                          target="_blank"
                        >
                          https://meet.google.com/{googleMeetingCode}
                        </a>
                        <br />
                        {/* Otherwise, to join by phone, dial +1 515-518-1961 and enter this PIN: 132 260 121#<br/>
To view more phone numbers, click this link: https://tel.meet/{meetCode}?hs=5</p> : */}
                        {t("labels.room_google_meet_desc2")}{" "}
                        <a
                          href={`https://tel.meet/${googleMeetingCode}`}
                          target="_blank"
                        >
                          https://tel.meet/{googleMeetingCode}?hs=5
                        </a>
                      </p>
                    ) : (
                      <p>{t("roomDialog.desc_escalated")}</p>
                    )}

                    <div className="side-content-action">
                      {isGoogleMeetingStarted ? (
                        <a
                          className="content-action-button"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              googleMeetShareTextRef.current?.innerText || "",
                            );
                          }}
                        >
                          {t("labels.share")}
                        </a>
                      ) : (
                        <a
                          className="content-action-button"
                          aria-disabled={!isGoogleLogined}
                          onClick={handleEscalated}
                        >
                          {t("roomDialog.escalated_btn_txt")}
                        </a>
                      )}
                    </div>
                  </div>
                </div>

              </div>

              <div className="side-action">
                <a
                  className="action-button action-button-danger"
                  onClick={() => {
                    trackEvent("share", "room closed");
                    onRoomDestroy();
                  }}
                >
                  {t("roomDialog.button_stopSession")}
                </a>
              </div>
            </div>
          )
        ) : (
          <div className="action-content">
            <div className="side-content-body">
              {t("roomDialog.room_join_tips")}
            </div>
            <div className="side-content-body">
              <Flex gap={10}>
                <Input
                  placeholder="Link Number "
                  type="text"
                  value={joinAddress}
                  onChange={(event) => {
                    setJoinAddress(event.target.value);
                  }}
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    await handleJoin();
                  }}
                >
                  {t("roomDialog.room_join_btn_txt")}
                </Button>
              </Flex>
            </div>
          </div>
        )}
      </>
    );
  };
  return <>{renderRoomDialog()}</>;
};

export default RoomDialog;
