import React, { useEffect, useState } from "react";
import { t } from "../i18n";
import { AppState } from "../types";
import "./ScreenSharing.scss";
import { TabList } from "./sidepanel/SidePanel";
import { nanoid } from "nanoid";
import copy from "copy-to-clipboard";
import { IconCopy, ShareScreenIcon, clipboard } from "./icons";
import FlashCollab from "./FlashCollab";
import { isScreenSharingAtom, plazaAPIAtom } from "../imago-app/Plaza";
import { useAtom } from "jotai";
import clsx from "clsx";
import { Button, Input, Radio } from "antd";
import { clearShareIsModerator } from "../imago-app/data/localStorage";

export const ScreenSharing: React.FC<{
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  id: string;
}> = ({
  appState,
  setAppState,
  id,
}) => {
    const [plazaAPI,] = useAtom(plazaAPIAtom);
    const [isWaitingAccept, setIsWaitingAccept] = useState(false);
    const [sentPinCode, setSentPinCode] = useState("");
    const { userInfo } = appState;
    const [isScreenSharing, setIsScreenSharing] = useAtom(isScreenSharingAtom);
    const [switchAction, setSwitchAction] = useState<"pinCode" | "shareTo">("pinCode");

    useEffect(() => {
      if (
        appState.screenSharing?.status == "confirm" &&
        appState.screenSharing?.isHost
      ) {
        setIsWaitingAccept(true);
      } else if (appState.screenSharing?.status == "sharing") {
        setIsWaitingAccept(false);
      } else {
        setIsWaitingAccept(false);
      }
    });
    return (
      <>
        <div className="action-switch">
          <Radio.Group
            defaultValue={switchAction}
            buttonStyle="outline"
            onChange={(e) => {
              setSwitchAction(e.target.value);
            }}
          >
            <Radio.Button value="pinCode">{t("labels.btn_pin_code")}</Radio.Button>
            <Radio.Button value="shareTo">{t("labels.btn_share_to")}</Radio.Button>
          </Radio.Group>
        </div>
        <div className="action-content">
          {switchAction == "pinCode" ?
            <>

              <div className="side-content-body">

                <p style={{ textAlign: "center" }}>{t("screenSharing.pincod_desc")}</p>
                <p style={{ textAlign: "center" }}>{t("labels.share_code_number_label")}:
                  <span
                    onDoubleClick={(e) => {
                      copy(plazaAPI?.pinCode || "");
                    }}
                    style={{ fontWeight: "bolder" }}
                  >
                    {plazaAPI?.pinCode}
                  </span>
                  <span
                    className="copy-pin-code"
                    onClick={(e) => {
                      copy(plazaAPI?.pinCode || "");
                    }}
                  >
                    {IconCopy}
                  </span>
                </p>
              </div>
            </> :
            <>
              <div className="margin75">
                <div className="side-content-body">
                  {isWaitingAccept && (
                    <p>
                      {t("screenSharing.share_wait", { pincode: sentPinCode })}
                    </p>
                  )}
                  {isScreenSharing &&
                    (appState.screenSharing?.isHost ? (
                      <p>
                        {t("screenSharing.sharing_text", {
                          pincode: sentPinCode,
                        })}
                      </p>
                    ) : (
                      <p>
                        {t("screenSharing.client_sharing_text", {
                          name: appState.screenSharing?.host?.userName!,
                        })}
                      </p>
                    ))}
                  {!isWaitingAccept && !isScreenSharing && (
                    <>
                      <p
                        className={clsx({
                          gray: !appState.userLicence?.screenShare,
                        })}
                      >
                        {t("screenSharing.share_to_desc")}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "left",
                          alignItems: "center",
                          gap: "20px"
                        }}
                      >
                        <Input
                          type="text"
                          disabled={!appState.userLicence?.screenShare}
                          value={sentPinCode}
                          onChange={(event) => {
                            setSentPinCode(event.target.value);
                          }}
                        />
                        {!isWaitingAccept && !isScreenSharing && (
                          <Button
                            type="primary"
                            aria-disabled={!appState.userLicence?.screenShare}
                            onClick={async () => {
                              if (!appState.userLicence?.screenShare) {
                                return;
                              }
                              if (sentPinCode != plazaAPI?.pinCode) {
                                clearShareIsModerator()
                                setIsWaitingAccept(true);
                                plazaAPI?.startScreenSharing(sentPinCode);
                              } else {
                                alert(t("labels.share_alert_1"));
                              }
                            }}
                          >
                            {t("screenSharing.share")}
                          </Button>
                          // <a
                          //   className="content-action-button"
                          //   onClick={() => {
                          //     location.href = "/pricing";
                          //   }}
                          // >
                          //   {t("screenSharing.upgrade")}
                          // </a>
                        )}
                      </div>
                    </>
                  )}
                </div>


              </div>
            </>}
        </div>
        {isScreenSharing && <div className="side-action">
          <a
            className="action-button"
            onClick={() => {
              setIsWaitingAccept(false);
              clearShareIsModerator();
              plazaAPI?.stopScreenSharing();
            }}
          >
            {t("screenSharing.stop")}
          </a>
        </div>}
      </>

    );
  };
