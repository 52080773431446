import clsx from "clsx";
import React, { useState } from "react";
import { ActionManager } from "../actions/manager";
import { CLASSES, COOKIES, FLASH_COLLAB, LIBRARY_SIDEBAR_WIDTH } from "../constants";
import { exportCanvas } from "../data";
import { isTextElement, showSelectedShapeActions } from "../element";
import { hideBrushIcon, showBrushIcon } from "../element/brush";
import {
  GetLoginedUserLicence,
  muteFSAbortError,
  updateActiveTool,
} from "../utils";
import { NonDeletedImagoElement } from "../element/types";
import { Language, t } from "../i18n";
import { calculateScrollCenter } from "../scene";
import { ExportType } from "../scene/types";
import {
  AppProps,
  AppState,
  ImagoProps,
  BinaryFiles,
  ImagoAPIRefValue,
  BinaryFileData,
  PointerDownState,
  StickyNote,
} from "../types";
import {
  SelectedShapeActions,
  BottomMoreActions,
  BottomColorPickerAction,
  GroupShapesSwitcher,
  BottomExtraActions,
  SelectedElementActions,
} from "./Actions";
import CollabButton from "./CollabButton";
import GoogleDriveButton from "./GoogleDriveButton";
import { ErrorDialog } from "./ErrorDialog";
import { ExportCB, ImageExportDialog } from "./ImageExportDialog";
import { FixedSideContainer } from "./FixedSideContainer";
import { HintViewer } from "./HintViewer";
import { Island } from "./Island";
import { LoadingMessage } from "./LoadingMessage";
import { MobileMenu } from "./MobileMenu";
import { PasteChartDialog } from "./PasteChartDialog";
import { Section } from "./Section";
import { HelpDialog } from "./HelpDialog";
import Stack from "./Stack";
import Library from "../data/library";
import { JSONExportDialog } from "./JSONExportDialog";
import { QrcodeUploadDialog } from "./QrcodeUploadDialog";
import { LibraryButton } from "./LibraryButton";
import { isImageFileHandle } from "../data/blob";
import { LibraryMenu } from "./LibraryMenu";
import { CollaborationPanel } from "./CollaborationPanel";
import { GoogleDrivePanel } from "./GoogleDrivePanel";

import "./LayerUI.scss";
import "./Toolbar.scss";
import { PenModeButton } from "./PenModeButton";
import { trackEvent } from "../analytics";
import {
  isMenuOpenAtom,
  useDevice,
  isFlashCollabingAtom,
} from "../components/App";
import {
  isScreenSharingAtom,
  plazaAPIAtom
} from "../imago-app/Plaza"
import { Stats } from "./Stats";
import { actionToggleStats } from "../actions/actionToggleStats";
import Footer from "./Footer";
import {
  ExportImageIcon,
  HamburgerMenuIcon,
  WelcomeScreenMenuArrow,
  CollabLogo,
  CanvasBackgroundIcon,
  createStringIcon,
  file,
  LoadIcon,
  IconClose,
  IconCloseWin,
} from "./icons";
import { Separator } from "./MenuUtils";
import { useOutsideClickHook } from "../hooks/useOutsideClick";
import WelcomeScreen from "./WelcomeScreen";
import { hostSidebarCountersAtom } from "./Sidebar/Sidebar";
import { jotaiScope } from "../jotai";
import { useAtom } from "jotai";
import { LanguageList } from "../imago-app/components/LanguageList";
import WelcomeScreenDecor from "./WelcomeScreenDecor";
import { getShortcutFromShortcutName } from "../actions/shortcuts";
import MenuItem from "./MenuItem";
import FlashCollab from "../components/FlashCollab";
import {
  CollaborateAudioVideoWhenAtom,
  collabAPIAtom,
} from "../imago-app/collab/Collab";
import toolbarsetting from "../toolbarconfig.json";
import { SHAPES } from "../shapes";
import { ToolFlashCollab } from "./ToolButton";

import { SidePanel } from "./sidepanel/SidePanel";
import { Toolbar } from "./Toolbar";
import { loadFromJSONPage } from "../data/json";
import ConfirmDialog from "./ConfirmDialog";
import usePortal from "react-useportal";
import { DraggablePanel } from "./DraggablePanel";
import { WebEmbedIframe } from "./WebEmbed";
import { checkIsShareModerator, isCollabtionHost, setShareIsModerator } from "../imago-app/data/localStorage";
import { Handwriting } from "../imago-app/Handwriting";
import { Modal } from "./Modal";
import { Suggestion } from "./Suggestion";
import { Dialog } from "./Dialog";
import { PageManager } from "../imago-app/data/PageManager";
import { SetSize } from "./SetSize";
import { IconTrash, IconZoomOut } from "./newIcons";
import { StickyNotePanel } from "./StickyNotePanel";
import { nanoid } from "nanoid";
import { FeaturesModal } from "./FeaturesModal";
import { Mermaid } from "./Mermaid";
import { FileSaveToModal } from "./FileSaveToModal";
import { ScanDownloadFile } from "./ScanDownloadFile";
import { BackgroundDialog } from "./Background";
import { Participants } from "./Participants";
import { HandWriteLangeModal } from "./HandWriteLangModal";
import { HandWriteSuggestModal } from "./HandWriteSuggestModal";
import { PenColorDialog } from "./PenColor";
import { StemSubject } from "./StemSubject";
import { StemSimulation } from "./StemSimulation";
import { stringToBase64 } from "../data/encode";
import { requestToken4flash } from "../imago-app/collab/RoomDialog";

interface LayerUIProps {
  actionManager: ActionManager;
  appState: AppState;
  files: BinaryFiles;
  canvas: HTMLCanvasElement | null;
  setAppState: React.Component<any, AppState>["setState"];
  elements: readonly NonDeletedImagoElement[];
  onCollabButtonClick?: () => void;
  onGoogleDriveClick?: () => void;
  onLockToggle: () => void;
  onPenModeToggle: () => void;
  onInsertElements: (elements: readonly NonDeletedImagoElement[]) => void;
  showExitZenModeBtn: boolean;
  langCode: Language["code"];
  isCollaborating: boolean;
  renderTopRightUI?: ImagoProps["renderTopRightUI"];
  renderCustomFooter?: ImagoProps["renderFooter"];
  renderCustomStats?: ImagoProps["renderCustomStats"];
  renderCustomSidebar?: ImagoProps["renderSidebar"];
  libraryReturnUrl: ImagoProps["libraryReturnUrl"];
  UIOptions: AppProps["UIOptions"];
  focusContainer: () => void;
  library: Library;
  id: string;
  operaPage?: ({
    page,
    actionName,
  }: {
    actionName?: string;
    page: string;
  }) => void;
  pageList?: string[];
  currPage?: string;
  onImageAction: (data: {
    insertOnCanvasDirectly: boolean;
    callback: () => void;
  }) => void;
  scanUploadImage: (
    data: { insertOnCanvasDirectly: boolean },
    imageFile: File,
  ) => void;
  renderWelcomeScreen: boolean;
  loadLocalLibToCanvas?: (type: any) => void;
  addFiles: (files: BinaryFileData[]) => void;
  deleteFiles: (fileIds: string[]) => void;
  onLogoutDelete: () => void;
  onLogoutSave: () => void;
  // screenSharing: {
  //   socket: SocketIOClient.Socket;
  //   pinCode: string;
  //   onAcceptConfirm: () => void;
  //   onAcceptCancel: () => void;
  //   onStartSharing: (sentPinCode: string) => void;
  //   onStopSharing: () => void;
  // };
  onLibraryItemTouchEnd?: (id: string, event: React.TouchEvent) => void;
  addFileToCanvas: (file: File) => Promise<void>;
  onLibraryPageChange: (page: number, pageSize: number) => void;
  handwriting: Handwriting;
  onHandwritingInsertImage: (url: string) => void;
  onHandwritingInsertText: (text: string) => void;
  onRoomCreated: () => void;
  onMermaidInsert: (file: File) => void;
  getStickyNotes: () => StickyNote[];
  handelAutoTextDrawInsertText?: (url: string, elements: NonDeletedImagoElement[]) => void
  onViewBackgroundColorChange?: (color: string) => void
}
const LayerUI = ({
  actionManager,
  appState,
  files,
  setAppState,
  elements,
  canvas,
  onCollabButtonClick,
  onGoogleDriveClick,
  onLockToggle,
  onPenModeToggle,
  onInsertElements,
  showExitZenModeBtn,
  isCollaborating,
  renderTopRightUI,
  renderCustomFooter,
  renderCustomStats,
  renderCustomSidebar,
  libraryReturnUrl,
  UIOptions,
  focusContainer,
  library,
  id,
  onImageAction,
  scanUploadImage,
  renderWelcomeScreen,
  loadLocalLibToCanvas,
  addFiles,
  operaPage,
  pageList,
  currPage,
  deleteFiles,
  onLogoutDelete,
  onLogoutSave,
  //screenSharing,
  onLibraryItemTouchEnd,
  addFileToCanvas,
  onLibraryPageChange,
  handwriting,
  onHandwritingInsertImage,
  onHandwritingInsertText,
  onRoomCreated,
  onMermaidInsert,
  getStickyNotes,
  handelAutoTextDrawInsertText,
  onViewBackgroundColorChange
}: LayerUIProps) => {
  const device = useDevice();
  const [plazaAPI,] = useAtom(plazaAPIAtom);
  const renderJSONExportDialog = () => {
    if (!UIOptions.canvasActions.export) {
      return null;
    }

    return (
      <JSONExportDialog
        elements={elements}
        appState={appState}
        files={files}
        actionManager={actionManager}
        exportOpts={UIOptions.canvasActions.export}
        canvas={canvas}
      />
    );
  };

  const renderQrcodeUploadDialog = () => {
    if (!UIOptions.canvasActions.export) {
      return null;
    }

    return (
      <QrcodeUploadDialog
        elements={elements}
        appState={appState}
        files={files}
        actionManager={actionManager}
        exportOpts={UIOptions.canvasActions.export}
        canvas={canvas}
        scanUploadImage={scanUploadImage}
      />
    );
  };

  const renderImageExportDialog = () => {
    if (!UIOptions.canvasActions.saveAsImage) {
      return null;
    }

    const createExporter =
      (type: ExportType): ExportCB =>
        async (exportedElements) => {
          trackEvent("export", type, "ui");
          const fileHandle = await exportCanvas(
            type,
            exportedElements,
            appState,
            files,
            {
              exportBackground: appState.exportBackground,
              name: appState.name,
              viewBackgroundColor: appState.viewBackgroundColor,
            },
          )
            .catch(muteFSAbortError)
            .catch((error) => {
              console.error(error);
              setAppState({ errorMessage: error.message });
            });

          if (
            appState.exportEmbedScene &&
            fileHandle &&
            isImageFileHandle(fileHandle)
          ) {
            setAppState({ fileHandle });
          }
        };

    return (
      <ImageExportDialog
        elements={elements}
        appState={appState}
        setAppState={setAppState}
        files={files}
        actionManager={actionManager}
        onExportToPng={createExporter("png")}
        onExportToSvg={createExporter("svg")}
        onExportToPdf={createExporter("pdf")}
        onExportToClipboard={createExporter("clipboard")}
      />
    );
  };

  const [isMenuOpen, setIsMenuOpen] = useAtom(isMenuOpenAtom);
  const [isFlashCollabing, setIsFlashCollabing] = useAtom(isFlashCollabingAtom);
  const [isScreenSharing, setIsScreenSharing] = useAtom(isScreenSharingAtom);
  const menuRef = useOutsideClickHook(() => setIsMenuOpen(false));
  const [collabAPI] = useAtom(collabAPIAtom);
  const [collaborateAudioVideoWhen, _] = useAtom(CollaborateAudioVideoWhenAtom);

  let activeRoomId = "";
  if (collabAPI) {
    activeRoomId = collabAPI.getActiveRoomId();
  }

  const renderCanvasActions = () => (
    <div style={{ position: "absolute", bottom: "10rem", right: "0" }}>
      {/* <WelcomeScreenDecor
        shouldRender={renderWelcomeScreen && !appState.isLoading}
      >
        <div className="virgil WelcomeScreen-decor WelcomeScreen-decor--menu-pointer">
          <div>{t("welcomeScreen.menuHints")}</div>
          <div style={{ transform: "scaleX(-1)" }}>{WelcomeScreenMenuArrow}</div>
        </div>
      </WelcomeScreenDecor> */}

      <button
        data-prevent-outside-click
        className={clsx("menu-button", "zen-mode-transition", {
          "transition-left": appState.zenModeEnabled,
        })}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        type="button"
        data-testid="menu-button"
      >
        {HamburgerMenuIcon}
      </button>

      {isMenuOpen && (
        <div
          ref={menuRef}
          style={{
            position: "absolute",
            bottom: "2.5rem",
            right: "0",
            marginTop: ".25rem",
          }}
        >
          <Section heading="canvasActions">
            {/* the zIndex ensures this menu has higher stacking order,
         see https://github.com/imago/imago/pull/1445 */}
            <Island
              className="menu-container"
              padding={2}
              style={{ zIndex: 1 }}
            >
              {!appState.viewModeEnabled && (
                <MenuItem
                  label={t("buttons.load")}
                  icon={LoadIcon}
                  onClick={async () => {
                    await loadFromJSONPage(appState, operaPage);
                  }}
                  dataTestId="load-button"
                  shortcut={getShortcutFromShortcutName("loadScene")}
                />
              )}
              {/* // TODO barnabasmolnar/editor-redesign  */}
              {/* is this fine here? */}
              {appState.fileHandle &&
                actionManager.renderAction("saveToActiveFile")}
              {renderJSONExportDialog()}
              {renderQrcodeUploadDialog()}
              {UIOptions.canvasActions.saveAsImage && (
                <MenuItem
                  label={t("buttons.exportImage")}
                  icon={ExportImageIcon}
                  dataTestId="image-export-button"
                  onClick={() => setAppState({ openDialog: "imageExport" })}
                  shortcut={getShortcutFromShortcutName("imageExport")}
                />
              )}
              {onCollabButtonClick && (
                <CollabButton
                  isCollaborating={isCollaborating}
                  collaboratorCount={appState.collaborators.size}
                  onClick={onCollabButtonClick}
                  appState={appState}
                  setAppState={setAppState}
                />
              )}
              {onGoogleDriveClick && (
                <GoogleDriveButton
                  onClick={onGoogleDriveClick}
                  appState={appState}
                  setAppState={setAppState}
                />
              )}
              {/**{actionManager.renderAction("toggleShortcuts", undefined, true)}*/}
              {!appState.viewModeEnabled &&
                actionManager.renderAction("clearCanvas")}
              <Separator />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: ".5rem",
                }}
              >
                {/* <div>{actionManager.renderAction("toggleTheme")}</div>*/}
                <div style={{ padding: "0 0.625rem" }}>
                  <LanguageList style={{ width: "100%" }} />
                </div>
                {!appState.viewModeEnabled && (
                  <MenuItem
                    label={t("labels.canvasBackground")}
                    icon={CanvasBackgroundIcon}
                    dataTestId=""
                    onClick={() => setAppState({})}
                  />
                )}
                {!appState.viewModeEnabled && (
                  <div>
                    <div style={{ padding: "0 0.625rem" }}>
                      {actionManager.renderAction("changeViewBackgroundColor")}
                    </div>
                  </div>
                )}
              </div>
            </Island>
          </Section>
        </div>
      )}
    </div>
  );

  const renderSelectedShapeActions = () => (
    <Section
      heading="selectedShapeActions"
      className={clsx("selected-shape-actions zen-mode-transition", {
        "transition-left": appState.zenModeEnabled,
      })}
    >
      <Island
        className={CLASSES.SHAPE_ACTIONS_MENU}
        padding={2}
        style={{
          // we want to make sure this doesn't overflow so subtracting the
          // approximate height of hamburgerMenu + footer
          maxHeight: `${appState.height - 166}px`,
        }}
      >
        <SelectedShapeActions
          appState={appState}
          elements={elements}
          renderAction={actionManager.renderAction}
        />
      </Island>
    </Section>
  );

  const renderFixedSideContainer = () => {
    let shouldRenderSelectedShapeActions =
      appState.openMenu === "shape" &&
      showSelectedShapeActions(appState, elements);

    // window.canvasBrush = (x: any, y: any) => {

    //   if (appState.activeTool.type === "eraserbig") {
    //     const nextActiveTool = updateActiveTool(appState, {
    //       type: 'freedraw',
    //     });
    //     setAppState({
    //       activeTool: nextActiveTool,
    //       multiElement: null,
    //       selectedElementIds: {},
    //     });
    //     hideBrushIcon()

    //   } else {
    //     const nextActiveTool = updateActiveTool(appState, {
    //       type: 'eraserbig',
    //     });
    //     setAppState({
    //       activeTool: nextActiveTool,
    //       multiElement: null,
    //       selectedElementIds: {},
    //     });
    //     showBrushIcon(x, y - 32)
    //   }

    // };

    if (appState.activeTool.type === "eraserbig") {
      shouldRenderSelectedShapeActions = false;
    }

    return (
      <FixedSideContainer side="top">
        {renderWelcomeScreen && !appState.isLoading && (
          <WelcomeScreen
            appState={appState}
            setAppState={setAppState}
            actionManager={actionManager}
          />
        )}
        <div className="App-menu App-menu_top">
          <Stack.Col
            gap={0}
            className={clsx("App-menu_top__left", {
              "disable-pointerEvents": appState.zenModeEnabled,
            })}
          >
            <div style={{ position: "relative" }}>
              <div id="brushIcon" className="brush-icon">
                <img src="/paint-brush-big.svg" width={40} height={40} />
              </div>
              <div id="rubber" className="brush-icon">
                <img src="/eraser-big.svg" width={130} height={130} />
              </div>
              {/* {CollabLogo} */}
            </div>
            {/* {renderCanvasActions()} */}
            {shouldRenderSelectedShapeActions && renderSelectedShapeActions()}
          </Stack.Col>
          {!appState.viewModeEnabled && (
            <Section heading="shapes" className="shapes-section">
              {(heading: React.ReactNode) => (
                <div style={{ position: "relative", width: "100%" }}>
                  <WelcomeScreenDecor
                    shouldRender={renderWelcomeScreen && !appState.isLoading}
                  >
                    <></>
                  </WelcomeScreenDecor>

                  {/* <HintViewer
                      appState={appState}
                      elements={elements}
                      isMobile={device.isMobile}
                      device={device}
                    /> */}
                </div>
              )}
            </Section>
          )}
          <div
            className={clsx(
              "layer-ui__wrapper__top-right zen-mode-transition",
              {
                "transition-right": appState.zenModeEnabled,
              },
            )}
          ></div>
        </div>
      </FixedSideContainer>
    );
  };

  const renderSidebars = () => {
    return appState.openSidebar !== null ? (
      <>
        {/* <SidePanel
          appState={appState}
          setAppState={setAppState}
          onInsertElements={onInsertElements}
          libraryReturnUrl={libraryReturnUrl}
          operaPage={operaPage}
          pageList={pageList}
          currPage={currPage}
          focusContainer={focusContainer}
          library={library}
          scanUploadImage={scanUploadImage}
          onImageAction={({ pointerType, callback }) => {
            onImageAction({
              insertOnCanvasDirectly: pointerType !== "mouse",
              callback,
            });
          }}
          files={files}
          deleteFiles={deleteFiles}
          isCollaborating={isCollaborating}
          addFiles={addFiles}
          id={id}
          // socket={screenSharing.socket}
          // pinCode={screenSharing.pinCode}
          // onStopSharing={screenSharing.onStopSharing}
          // onStartSharing={screenSharing.onStartSharing}
          onLibraryItemTouchEnd={onLibraryItemTouchEnd}
          onLibraryPageChange={onLibraryPageChange}
          onRoomCreated={onRoomCreated}
        /> */}
        <FeaturesModal
          appState={appState}
          setAppState={setAppState}
          onInsertElements={onInsertElements}
          libraryReturnUrl={libraryReturnUrl}
          focusContainer={focusContainer}
          library={library}
          scanUploadImage={scanUploadImage}
          onImageAction={({ pointerType, callback }) => {
            onImageAction({
              insertOnCanvasDirectly: pointerType !== "mouse",
              callback,
            });
          }}
          files={files}
          deleteFiles={deleteFiles}
          isCollaborating={isCollaborating}
          addFiles={addFiles}
          id={id}
          onLibraryItemTouchEnd={onLibraryItemTouchEnd}
          onLibraryPageChange={onLibraryPageChange}
          onRoomCreated={onRoomCreated}
          addFileToCanvas={addFileToCanvas}
          onMermaidInsert={onMermaidInsert}
        />
      </>
    ) : null;
  };

  // const renderSidebars = () => {
  //   return appState.openSidebar === "customSidebar" ? (
  //     renderCustomSidebar?.() || null
  //   ) : appState.openSidebar === "library" ? (
  //     <LibraryMenu
  //       appState={appState}
  //       onInsertElements={onInsertElements}
  //       libraryReturnUrl={libraryReturnUrl}
  //       focusContainer={focusContainer}
  //       library={library}
  //       id={id}
  //     />
  //   ) : appState.openSidebar === "collaboration" ? (
  //     <CollaborationPanel
  //       appState={appState}
  //       onInsertElements={onInsertElements}
  //       libraryReturnUrl={libraryReturnUrl}
  //       focusContainer={focusContainer}
  //       library={library}
  //       id={id}
  //     />
  //   ) : appState.openSidebar === "googleDrive" ? (
  //     <GoogleDrivePanel
  //       appState={appState}
  //       onInsertElements={onInsertElements}
  //       libraryReturnUrl={libraryReturnUrl}
  //       focusContainer={focusContainer}
  //       library={library}
  //       id={id}
  //     />
  //   ) : null;
  // };

  const [hostSidebarCounters] = useAtom(hostSidebarCountersAtom, jotaiScope);

  renderCustomFooter = () => (
    <Section heading="shapes" className="shapes-section">
      {(heading: React.ReactNode) => (
        <div style={{ position: "relative" }}>
          {/* <WelcomeScreenDecor
            shouldRender={renderWelcomeScreen && !appState.isLoading}
          >
            <div className="virgil WelcomeScreen-decor WelcomeScreen-decor--top-toolbar-pointer">
              <div className="WelcomeScreen-decor--top-toolbar-pointer__label">
                {t("welcomeScreen.toolbarHints")}
              </div>
            </div>
          </WelcomeScreenDecor> */}

          <Stack.Col gap={4} align="start">
            <Stack.Row
              gap={1}
              className={clsx("App-toolbar-container", {
                "zen-mode": appState.zenModeEnabled,
              })}
            >
              <Island
                padding={1}
                className={clsx("App-toolbar", {
                  "zen-mode": appState.zenModeEnabled,
                })}
              >
                {heading}
                <Stack.Row gap={1}>
                  {/* <PenModeButton
                    zenModeEnabled={appState.zenModeEnabled}
                    checked={appState.penMode}
                    onChange={onPenModeToggle}
                    title={t("toolBar.penMode")}
                    penDetected={appState.penDetected}
                  /> */}
                  {/**
                  <LockButton
                    zenModeEnabled={appState.zenModeEnabled}
                    checked={appState.activeTool.locked}
                    onChange={() => onLockToggle()}
                    title={t("toolBar.lock")}
                  />
                  <div className="App-toolbar__divider"></div>
                  */}

                  <Toolbar
                    actionManager={actionManager}
                    appState={appState}
                    setAppState={setAppState}
                    toolbarSetting={toolbarsetting.toolbar.large.groups}
                    canvas={canvas}
                    operaPage={operaPage}
                    isCollaborating={isCollaborating}
                    isFlashCollabing={isFlashCollabing}
                    activeRoomId={activeRoomId}
                    onImageAction={onImageAction}
                    onCollabButtonClick={onCollabButtonClick}
                    onFlashCollabClick={() =>
                      setIsFlashCollabing(!isFlashCollabing)
                    }
                    loadLocalLibToCanvas={loadLocalLibToCanvas}
                    addFileToCanvas={addFileToCanvas}
                  />

                  {/* <div className="App-toolbar__divider"></div>
                  <ShapesSwitcher
                    appState={appState}
                    canvas={canvas}
                    activeTool={appState.activeTool}
                    setAppState={setAppState}
                    activeRoomId={activeRoomId}
                    isFlashCollabing={isFlashCollabing}
                    onFlashCollabClick={() => setIsFlashCollabing(!isFlashCollabing)}
                    onImageAction={({ pointerType }) => {
                      onImageAction({
                        insertOnCanvasDirectly: pointerType !== "mouse",
                      });
                    }}
                  /> */}
                  {/* {actionManager.renderAction("eraser", {
                  // size: "small",
                })} */}
                </Stack.Row>
              </Island>
            </Stack.Row>
          </Stack.Col>
        </div>
      )}
    </Section>
  );
  //if (isScreenSharing) debugger
  const flashUserInfo = {
    displayName: appState.userInfo?.username!,
    email: appState.userInfo?.email!,
  };

  const isCollabModerator = isCollabtionHost();
  const isShareModerator = checkIsShareModerator();

  return (
    <>
      {isFlashCollabing &&
        activeRoomId != null &&
        activeRoomId != "" &&
        (collaborateAudioVideoWhen == "Audio+Video" ? (
          <DraggablePanel
            id="flashCollabration"
            key={"flashCollabration"}
            title=""
            width={320}
            height={180}
            appState={appState}
            setAppState={setAppState}
            buttons={["minisize", "refresh", "floating", "maxsize"]}
            onMinisize={() => {
              setAppState({ showFlashCollabration: false });
            }}
            currentState={
              appState.showFlashCollabration ? "floating" : "minisize"
            }
            position={[10, 10]}
          >
            <FlashCollab
              roomId={activeRoomId}
              isModerator={isCollabModerator}
              key={"flashCollabration"}
              configOverwrite={{
                toolbarButtons: ["microphone", "camera", "filmstrip"],
                prejoinConfig: { enabled: false },
                startWithAudioMuted: !isCollabtionHost(),
              }}
              userInfo={flashUserInfo}
            />
          </DraggablePanel>
        ) : (
          <DraggablePanel
            id="flashCollabration"
            key={"flashCollabration"}
            title=""
            width={320}
            height={180}
            appState={appState}
            setAppState={setAppState}
            buttons={["minisize", "refresh", "floating", "maxsize"]}
            onMinisize={() => {
              setAppState({ showFlashCollabration: false });
            }}
            currentState={
              appState.showFlashCollabration ? "floating" : "minisize"
            }
            position={[10, 10]}
          >
            <FlashCollab
              roomId={activeRoomId}
              key={"flashCollabration"}
              isModerator={isCollabModerator}
              configOverwrite={{
                toolbarButtons: ["microphone", "filmstrip"],
                prejoinConfig: { enabled: false },
                //startAudioOnly:true,
                startWithAudioMuted: !isCollabtionHost(),
                startWithVideoMuted: true,
              }}
              userInfo={flashUserInfo}
            />
          </DraggablePanel>
        ))}
      {appState.screenSharing?.isHost ? (
        <DraggablePanel
          id="screenSharing"
          key={"screenSharing"}
          title={t("winTabList.shareScreen")}
          width={320}
          height={180}
          appState={appState}
          setAppState={setAppState}
          buttons={["minisize", "refresh", "floating", "maxsize"]}
          onMinisize={() => {
            setAppState({ showScreenSharing: false });
          }}
          currentState={appState.showScreenSharing ? "floating" : "minisize"}
          position={[10, 10]}
        >
          <FlashCollab
            roomId={appState.screenSharing?.pinCode!}
            key={"screenSharing"}
            isModerator={isShareModerator}
            configOverwrite={{
              toolbarButtons: ["microphone", "desktop", "filmstrip"],
              prejoinConfig: { enabled: false },
              //startAudioOnly:true,
              startWithAudioMuted: false,
              startWithVideoMuted: true,
            }}
            userInfo={flashUserInfo}
          />
        </DraggablePanel>
      ) : (
        isScreenSharing && (
          <DraggablePanel
            id="screenSharing"
            key={"screenSharing"}
            title={t("winTabList.shareScreen")}
            width={640}
            height={360}
            appState={appState}
            setAppState={setAppState}
            buttons={["minisize", "refresh", "floating", "maxsize"]}
            onMinisize={() => {
              setAppState({ showScreenSharing: false });
            }}
            currentState={appState.showScreenSharing ? "default" : "minisize"}
          >
            <FlashCollab
              roomId={appState.screenSharing?.pinCode!}
              key={"screenSharing"}
              isModerator={isShareModerator}
              configOverwrite={{
                toolbarButtons: ["microphone", "filmstrip"],
                prejoinConfig: { enabled: false },
                startWithAudioMuted: false,
                startWithVideoMuted: true,
              }}
              userInfo={flashUserInfo}
            />
          </DraggablePanel>
        )
      )}
      {/* <DraggablePanel title="Screen Sharing"  width={640} height={360} appState={appState} setAppState={setAppState}>
        <FlashCollab roomId={"23232222"}      
          configOverwrite={{
            toolbarButtons : ["microphone","desktop","filmstrip"],
            prejoinConfig:{enabled:false},
            //startAudioOnly:true,
            startWithAudioMuted:true,
            startWithVideoMuted:true
          }} 
          userInfo={{displayName:appState.userInfo?.username!,email:appState.userInfo?.email!}}/>
      </DraggablePanel> */}
      {!appState.screenSharing?.isHost &&
        appState.screenSharing?.status == "confirm" && (
          <ConfirmDialog
            onConfirm={async () => {
              //screenSharing.onAcceptConfirm();            
              setShareIsModerator()
              appState.userInfo && await requestToken4flash(appState.userInfo);
              plazaAPI?.screenShareRespond(true);
              setAppState({ showScreenSharing: true });
            }}
            onCancel={() => {
              // screenSharing.onAcceptCancel();
              plazaAPI?.screenShareRespond(false);
            }}
            title={t("screenSharing.title")}
          >
            <p className="">
              {" "}
              {t("screenSharing.share_confirm", {
                name: appState.screenSharing?.host?.userName!,
              })}
            </p>
          </ConfirmDialog>
        )}

      {appState.webEmbed.map((we, i) => {
        return (<>
          <WebEmbedIframe
            key={we.id}
            webEmbed={we}
            appState={appState}
            setAppState={setAppState}
          ></WebEmbedIframe></>
        );
      })}
      {appState.stickyNotes?.map((sn, i) => {
        return <StickyNotePanel
          key={sn.key}
          id={sn.id}
          stickyNote={sn}
          appState={appState}
          setAppState={setAppState}
          defaultPosition={[(window.innerWidth - 320) / 2 + i * 10, (window.innerHeight - 320) / 2 + i * 10] as [number, number]}
          getStickyNotes={getStickyNotes}
        />;
      })}
      {/* {appState.openPopup == "handwritingSuggestion" &&
        <Dialog title="Suggestion" onCloseRequest={() => { setAppState({ openPopup: null }) }}>
          <Suggestion appState={appState} onInsertImage={(url: string) => {
            onHandwritingInsertImage(url);
            setAppState({ openPopup: null });
          }}
            onInsertText={(text: string) => {
              onHandwritingInsertText(text);
              setAppState({ openPopup: null });
            }} />
        </Dialog>} */}
      {/* {appState.isLoading && <LoadingMessage delay={250} />} */}
      {appState.errorMessage && (
        <ErrorDialog
          message={appState.errorMessage}
          onClose={() => setAppState({ errorMessage: null })}
        />
      )}
      {appState.openPopup == "setSize" &&
        <Dialog small title="Set Height" onCloseRequest={() => { setAppState({ openPopup: null }) }}>
          <SetSize
            appState={appState}
            elements={elements}
          />
        </Dialog>}
      {
        appState.openDialog == "mermaid" && <Dialog title="Mermaid Chart" onCloseRequest={() => { setAppState({ openDialog: null }) }}>
          <div style={{ padding: "20px" }}>
            <Mermaid
              appState={appState}
              setAppState={setAppState}
              onInsert={(f) => {
                onMermaidInsert(f);
                setAppState({ openDialog: null });
              }}

            />
          </div>
        </Dialog>
      }
      {
        appState.openDialog == "stem" && <Dialog title={t("labels.stem")} onCloseRequest={() => { setAppState({ openDialog: null }) }}>
          <div style={{ padding: "20px" }}>
            <StemSubject
              appState={appState}
              setAppState={setAppState}

            />
          </div>
        </Dialog>
      }
      {
        appState.openDialog == "stemSimulation" && <Dialog title={<span style={{ marginLeft: '30px' }}>{t("labels.stem")}</span>} onCloseRequest={() => { setAppState({ openDialog: null }) }}>
          <div style={{ padding: "2px" }}>
            <StemSimulation
              appState={appState}
              setAppState={setAppState}
            />
          </div>
        </Dialog>
      }

      {appState.openDialog == "fileSaveTo" && (
        <FileSaveToModal
          appState={appState}
          id="file-save-to"
          isMobile={device.isMobile}
          onClose={() => { setAppState({ openDialog: null }) }}
          setAppState={setAppState}
        />
      )}
      {appState.openDialog == "scanDownload" && (
        <Modal labelledBy="" maxWidth={240} closeOnClickOutside={true} onCloseRequest={() => { setAppState({ openDialog: null }) }}>
          <div className={"Island-close"}>
            <a href={undefined} onClick={() => { setAppState({ openDialog: null }) }}>{IconCloseWin}</a>
          </div>

          <div style={{ display: "flex", justifyContent: "center", paddingBottom: "30px" }}>
            <ScanDownloadFile appState={appState} />
          </div>


        </Modal>
      )}
      {appState.openDialog === "setBackground" && (
        <BackgroundDialog
          defaultBackgroundColor={appState.viewBackgroundColor}
          onClose={() => {
            setAppState({ openDialog: null });
          }}
          setAppState={setAppState}
          onViewBackgroundColorChange={onViewBackgroundColorChange}
        />
      )}
      {appState.openDialog === "setPenColor" && (
        <PenColorDialog
          defaultBackgroundColor={appState.viewBackgroundColor}
          onClose={() => {
            setAppState({ openDialog: null });
          }}
          setAppState={setAppState}
          onPenColorChange={onViewBackgroundColorChange}
        />
      )}
      {appState.openDialog === "collaborators" && (
        <Participants
          appState={appState}
          setAppState={setAppState}
          isFlashCollabing={isFlashCollabing}
          onCallClick={() => {
            setIsFlashCollabing(!isFlashCollabing);

            isFlashCollabing && setAppState({ openDialog: null });
          }}
          mobile={device.isMobile}
          actionManager={actionManager}
        />
      )}

      {
        appState.isShowSetHandWriteLangPanel && (
          (<>

            <HandWriteLangeModal appState={appState}
              setAppState={setAppState} />

          </>)
        )
      }

      {
        appState.openPopup == "handwritingSuggestion" && (<>

          <HandWriteSuggestModal
            onHandwritingInsertImage={(url: string) => {
              onHandwritingInsertImage(url)
              setAppState({ openPopup: null });
            }}
            onHandwritingInsertText={(text: string) => {
              onHandwritingInsertImage(text)
              setAppState({ openPopup: null });
            }}
            appState={appState}
            setAppState={setAppState} />

        </>)
      }

      {appState.openDialog === "help" && (
        <HelpDialog
          onClose={() => {
            setAppState({ openDialog: null });
          }}
        />
      )}
      {renderImageExportDialog()}
      {appState.pasteDialog.shown && (
        <PasteChartDialog
          setAppState={setAppState}
          appState={appState}
          onInsertChart={onInsertElements}
          onClose={() =>
            setAppState({
              pasteDialog: { shown: false, data: null },
            })
          }
        />
      )}
      {device.isMobile && (
        <MobileMenu
          renderWelcomeScreen={renderWelcomeScreen}
          appState={appState}
          elements={elements}
          actionManager={actionManager}
          renderJSONExportDialog={renderJSONExportDialog}
          renderImageExportDialog={renderImageExportDialog}
          setAppState={setAppState}
          onCollabButtonClick={onCollabButtonClick}
          onGoogleDriveClick={onGoogleDriveClick}
          activeRoomId={activeRoomId}
          isFlashCollabing={isFlashCollabing}
          onFlashCollabClick={() => setIsFlashCollabing(!isFlashCollabing)}
          operaPage={operaPage}
          onLockToggle={() => onLockToggle()}
          onPenModeToggle={onPenModeToggle}
          loadLocalLibToCanvas={loadLocalLibToCanvas}
          canvas={canvas}
          isCollaborating={isCollaborating}
          renderCustomFooter={renderCustomFooter}
          onImageAction={onImageAction}
          renderTopRightUI={renderTopRightUI}
          renderCustomStats={renderCustomStats}
          renderSidebars={renderSidebars}
          device={device}
          toolbarSetting={toolbarsetting.toolbar.small.groups}
          onLogoutDelete={onLogoutDelete}
          onLogoutSave={onLogoutSave}
          addFileToCanvas={addFileToCanvas}
          onViewBackgroundColorChange={onViewBackgroundColorChange}
        />
      )}

      {!device.isMobile && (
        <>
          <div
            className={clsx("layer-ui__wrapper", {
              "disable-pointerEvents":
                appState.draggingElement ||
                appState.resizingElement ||
                (appState.editingElement &&
                  !isTextElement(appState.editingElement)),
            })}
            style={
              ((appState.openSidebar === "library" &&
                appState.isSidebarDocked) ||
                hostSidebarCounters.docked) &&
                device.canDeviceFitSidebar
                ? { width: `calc(100% - ${LIBRARY_SIDEBAR_WIDTH}px)` }
                : {}
            }
          >
            {renderFixedSideContainer()}
            <Footer
              renderWelcomeScreen={renderWelcomeScreen}
              appState={appState}
              actionManager={actionManager}
              renderCustomFooter={renderCustomFooter}
              showExitZenModeBtn={showExitZenModeBtn}
              isCollaborating={isCollaborating}
              onCollabButtonClick={onCollabButtonClick}
              setAppState={setAppState}
              onLogoutDelete={onLogoutDelete}
              onLogoutSave={onLogoutSave}
              operaPage={operaPage}
              onViewBackgroundColorChange={onViewBackgroundColorChange}
            />
            {appState.showStats && (
              <Stats
                appState={appState}
                setAppState={setAppState}
                elements={elements}
                onClose={() => {
                  actionManager.executeAction(actionToggleStats);
                }}
                renderCustomStats={renderCustomStats}
              />
            )}
            {appState.scrolledOutside && (
              <button
                className="scroll-back-to-content"
                onClick={() => {
                  setAppState({
                    ...calculateScrollCenter(elements, appState, canvas),
                  });
                }}
              >
                {t("buttons.scrollBackToContent")}
              </button>
            )}
          </div>
          {renderSidebars()}
        </>
      )}
      <SelectedElementActions
        appState={appState}
        elements={elements}
        renderAction={actionManager.renderAction}
        handelAutoTextDrawInsertText={handelAutoTextDrawInsertText}
        setAppState={setAppState}
        handwriting={handwriting}
      />
    </>
  );
};

const areEqual = (prev: LayerUIProps, next: LayerUIProps) => {
  const getNecessaryObj = (appState: AppState): Partial<AppState> => {
    const {
      suggestedBindings,
      startBoundElement: boundElement,
      ...ret
    } = appState;
    return ret;
  };
  const prevAppState = getNecessaryObj(prev.appState);
  const nextAppState = getNecessaryObj(next.appState);

  const keys = Object.keys(prevAppState) as (keyof Partial<AppState>)[];

  return (
    prev.renderCustomFooter === next.renderCustomFooter &&
    prev.renderTopRightUI === next.renderTopRightUI &&
    prev.renderCustomStats === next.renderCustomStats &&
    prev.renderCustomSidebar === next.renderCustomSidebar &&
    prev.langCode === next.langCode &&
    prev.elements === next.elements &&
    prev.files === next.files &&
    keys.every((key) => prevAppState[key] === nextAppState[key])
  );
};

export default React.memo(LayerUI, areEqual);
